import React, { FormEvent, MouseEvent, useState } from "react";
import TextField from "@mui/material/TextField";
import { validateUser } from "../api/validate-user";
import { validateCharity } from "../api/validate-charity";
import { checkAvailableFunds } from "../api/available-funds";
import { getFileUploadURLs } from "../api/file-upload";
import { submitFormToGivinga } from "../api/submit-form";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ValidationSuccess from "./ValidationSuccess";
import ValidationFailure from "./ValidationFailure";

import currency from "currency.js";
import { TailSpin } from "react-loader-spinner";

interface CharityAddress {
  street: string;
  street2: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
}

export interface Charity {
  name: string;
  ein: string;
  address: CharityAddress;
  charityId: string;
}

export interface User {
  firstName: string;
  lastName: string;
  email: string;
  id: string;
  employeeAccountNumber: string;
}

export interface FileUpload {
  name: string;
  type: string;
}

export interface FormSubmission {
  accountNumber: string;
  charityId: string;
  amount: number;
  activityId: string;
  notes: string;
  date: string;
  matchRequested: boolean;
  offline: boolean;
}

const RequestForm = () => {
  const [emailData, setEmailData] = useState<string>("");
  const [taxID, setTaxID] = useState<string>("");
  const [donationDate, setDonationDate] = useState<Date | null>(null);
  const [donationReceiptFile, setDonationReceiptFile] = useState<File | null>(
    null
  );

  const [matchAmount, setMatchAmount] = useState<number | string>("");
  const [designation, setDesignation] = useState<string>("");

  const [userData, setUserData] = useState<User>({
    firstName: "",
    lastName: "",
    email: "",
    id: "",
    employeeAccountNumber: "",
  });
  const [userError, setUserError] = useState<boolean>(false);
  const [userSuccess, setUserSuccess] = useState<boolean>(false);

  const [charity, setCharity] = useState<Charity>({
    name: "",
    ein: "",
    address: {} as CharityAddress,
    charityId: "",
  });
  const [charityError, setCharityError] = useState<boolean>(false);
  const [charitySuccess, setCharitySuccess] = useState<boolean>(false);

  const [availableUserFunds, setAvailableUserFunds] = useState<string>("");
  const [remainingFundsError, setRemainingFundsError] =
    useState<boolean>(false);

  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
  const ref = React.useRef();
  const handleEmailVerification = async (e: MouseEvent, data: string) => {
    e.preventDefault();
    const validUser = await validateUser(data);
    if (validUser.error) {
      setUserError(true);
    } else {
      const { firstName, lastName, userId, email, employeeAccountNumber } =
        validUser.response;

      setUserData({
        firstName: firstName,
        lastName: lastName,
        email: email,
        id: userId,
        employeeAccountNumber: employeeAccountNumber,
      });
      setUserSuccess(true);
    }
  };

  const handleCharityVerification = async (e: MouseEvent, data: string) => {
    e.preventDefault();
    const validCharity = await validateCharity(data);
    const { charities } = validCharity.response;
    if (charities.length < 1) {
      setCharityError(true);
    } else {
      const { name, ein, address, id } = charities[0];
      const { street, street2, city, state, zip, phone } = address;
      const charityAddress: CharityAddress = {
        street,
        street2,
        city,
        state,
        zip,
        phone,
      };
      const charityData: Charity = {
        name: name,
        ein: ein,
        address: charityAddress,
        charityId: id,
      };
      setCharity(charityData);
      setCharitySuccess(true);
    }
  };

  const handleMatchAmountInput = (input: string) => {
    const filteredInput = input
      .replace(/[^0-9.]/g, "")
      .replace(/(\..*?)\..*/g, "$1");
    setMatchAmount(filteredInput);
  };

  const onUserSuccess = async () => {
    const funds = await checkAvailableFunds(userData.id);
    const { availableFunds } = funds.response;
    setAvailableUserFunds(availableFunds);
  };

  const onUserFailure = () => {
    setEmailData("");
    setUserData({
      firstName: "",
      lastName: "",
      email: "",
      id: "",
      employeeAccountNumber: "",
    });
  };

  const onCharityFailure = () => {
    setTaxID("");
    setCharity({
      name: "",
      ein: "",
      address: {} as CharityAddress,
      charityId: "",
    });
  };

  const updateRemainingBalance = () => {
    const match = currency(matchAmount);
    const funds = currency(availableUserFunds);
    const remaining = currency(funds).subtract(match);
    if (currency(remaining).intValue < 0 && !remainingFundsError) {
      setRemainingFundsError(true);
    }
    if (currency(remaining).intValue > 0 && remainingFundsError) {
      setRemainingFundsError(false);
    }
    return currency(remaining).format();
  };

  const uploadFile = async (file: File, url: string) => {
    const options: RequestInit = {
      method: "PUT",
      body: file,
    };
    try {
      const response = await fetch(url, options);
      return response;
    } catch (e) {
      alert("Donation receipt failed to upload. Please try again.");
      setIsFormSubmitting(false);
    }
  };

  const handleFileUploadURLs = async (file: File) => {
    const uploadParams: FileUpload = {
      name: file.name.replaceAll(" ", "_"),
      type: file.type,
    };
    const getPresignedUrl = await getFileUploadURLs(uploadParams);
    const { uploadURL, fileURL } = getPresignedUrl.response;
    return { uploadURL, fileURL };
  };

  const clearFormInputs = () => {
    setEmailData("");
    setUserData({
      firstName: "",
      lastName: "",
      email: "",
      id: "",
      employeeAccountNumber: "",
    });
    setTaxID("");
    setCharity({
      name: "",
      ein: "",
      address: {} as CharityAddress,
      charityId: "",
    });
    setDonationDate(null);
    setMatchAmount("");
    setDesignation("");
    setDonationReceiptFile(null);
    setAvailableUserFunds("");
    ref.current.value = "";
  };

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setIsFormSubmitting(true);

    if (!donationReceiptFile || !donationDate) {
      return;
    }
    if (currency(matchAmount).value <= 0) {
      setIsFormSubmitting(false);
      alert("Match amount must be greater than $0.00");
      return;
    }
    if (remainingFundsError) {
      setIsFormSubmitting(false);
      alert("You don't have enough funds");
      return;
    }
    const { uploadURL, fileURL } = await handleFileUploadURLs(
      donationReceiptFile
    );

    const formSubmissionData: FormSubmission = {
      accountNumber: userData.employeeAccountNumber,
      charityId: charity.charityId,
      amount: currency(matchAmount).value,
      activityId: fileURL,
      notes: designation,
      date: donationDate
        .toLocaleDateString("en-US", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        })
        .split("/")
        .join("-"),
      matchRequested: true,
      offline: true,
    };
    await uploadFile(donationReceiptFile, uploadURL);

    const submissionResponse = await submitFormToGivinga(formSubmissionData);
    setIsFormSubmitting(false);
    clearFormInputs();
    if (submissionResponse.error) {
      alert("There was an error with submitting the form. Please try again.");
    } else {
      alert("Form submission complete!");
    }
  };

  return (
    <>
      {charitySuccess ? (
        <ValidationSuccess
          charity={charity}
          close={setCharitySuccess}
          success={() => {
            return;
          }}
          failure={onCharityFailure}
        />
      ) : userSuccess ? (
        <ValidationSuccess
          user={userData}
          close={setUserSuccess}
          success={onUserSuccess}
          failure={onUserFailure}
        />
      ) : (
        <form
          className="px-6 lg:mr-52 2xl:w-1/2 mt-10 flex text-xs sm:text-base border-x-2 border-gray-100 flex-col"
          id={"match-request"}
          onSubmit={onSubmit}
        >
          <div
            className={
              "flex flex-col pt-10 pb-10 gap-6 border-b-2 border-gray-100"
            }
          >
            <div className={"flex flex-wrap gap-4 justify-between"}>
              <label htmlFor={"user-email"} hidden={true} />
              <TextField
                className={"flex-1 border border-gray-300 rounded p-3.5"}
                type="email"
                label={"Your work email"}
                name={"user-email"}
                id={"user-email"}
                value={emailData}
                required
                onChange={(e) => setEmailData(e.target.value)}
              />
              <button
                className={
                  "flex bg-blue-800 text-white rounded-lg py-1 px-3 sm:py-4 sm:px-10 sm:text-lg items-center justify-center"
                }
                onClick={(e) => handleEmailVerification(e, emailData)}
              >
                Verify
              </button>
            </div>
            <div className={"flex flex-wrap gap-4 justify-between"}>
              <label htmlFor={"ein"} hidden={true} />
              <TextField
                className={"flex-1 border border-gray-300 rounded p-3.5"}
                label={"Charity EIN/Tax ID Number"}
                value={taxID}
                type="text"
                name={"ein"}
                id={"ein"}
                required
                onChange={(e) => setTaxID(e.target.value)}
              />
              <button
                className={
                  "flex bg-blue-800 text-white rounded-lg py-1 px-3 sm:py-4 sm:px-10 sm:text-lg items-center justify-center"
                }
                onClick={(e) =>
                  handleCharityVerification(e, taxID.replace("-", ""))
                }
              >
                Verify
              </button>
            </div>
            <span>
              Need to lookup an EIN?{" "}
              <a
                href="https://hub.givinga.com/charities"
                target="_blank"
                rel="noreferrer"
              >
                <b>Click here</b>
              </a>
            </span>
          </div>
          <div
            className={
              "flex flex-col pt-10 pb-20 border-b border-gray-300 gap-6"
            }
          >
            <label htmlFor={"company-name"} hidden={true} />
            <TextField
              label={"Company Name"}
              type="text"
              className={
                "bg-gray-100 flex-1 border border-gray-300 rounded p-3.5"
              }
              InputProps={{
                readOnly: true,
              }}
              value={process.env.REACT_APP_COMPANY_NAME}
              name={"company-name"}
              id={"company-name"}
            />
            <label htmlFor={"user-name"} hidden={true} />
            <TextField
              label={"Your Name (requires above verification)"}
              type="text"
              className={
                "bg-gray-100 flex-1 border border-gray-300 rounded p-3.5"
              }
              InputProps={{
                readOnly: true,
              }}
              name={"user-name"}
              id={"user-name"}
              required
              value={
                userData.firstName &&
                `${userData.firstName} ${userData.lastName}`
              }
            />
            <label htmlFor={"charity-name"} hidden={true} />
            <TextField
              label={"Charity (requires above verification)"}
              type="text"
              className={
                "bg-gray-100 flex-1 border border-gray-300 rounded p-3.5"
              }
              InputProps={{
                readOnly: true,
              }}
              required
              name={"charity-name"}
              id={"charity-name"}
              value={charity.name}
            />
            <label htmlFor={"donation-date"} hidden={true} />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label={"Donation Date"}
                inputFormat={"yyyy-dd-MM"}
                maxDate={new Date()}
                mask={"____-__-__"}
                renderInput={(params) => (
                  <TextField
                    className={"w-1/2"}
                    required
                    name={"donation-date"}
                    id={"donation-date"}
                    {...params}
                  />
                )}
                value={donationDate}
                onChange={(date: Date) => setDonationDate(date)}
                className={"border border-gray-300 rounded p-3.5"}
              />
            </LocalizationProvider>
            <div className={"flex flex-1 gap-2"}>
              <div className={"flex flex-1 flex-col"}>
                <label htmlFor={"donation-date"} hidden />
                <TextField
                  type="text"
                  className={
                    remainingFundsError
                      ? "border focus:outline-red-300 border-red-300 rounded p-3.5"
                      : "border border-gray-300 rounded p-3.5"
                  }
                  label={"Match Amount"}
                  required
                  name={"match-amount"}
                  id={"match-amount"}
                  value={matchAmount}
                  onChange={(e) => handleMatchAmountInput(e.target.value)}
                />
                {remainingFundsError ? (
                  <small className={"self-end text-red-700 text-sm sm:text-lg"}>
                    {`${updateRemainingBalance()} Remaining`}
                  </small>
                ) : (
                  <small
                    className={"self-end text-emerald-700 text-sm sm:text-lg"}
                  >
                    {`${updateRemainingBalance()} Remaining`}
                  </small>
                )}
              </div>
              {remainingFundsError && (
                <div
                  className={
                    "flex flex-col justify-center bg-red-600 text-white font-bold rounded-md w-7"
                  }
                >
                  <span className={"self-center"}>X</span>
                </div>
              )}
            </div>
            <label htmlFor={"designation"} hidden />
            <TextField
              type="text"
              className={"flex-1 border border-gray-300 rounded p-3.5"}
              label={"Designation (optional - to show on the check)"}
              name={"designation"}
              id={"designation"}
              value={designation}
              onChange={(e) => setDesignation(e.target.value)}
            />
            <label htmlFor="file-upload" className={"flex flex-col gap-2"}>
              Donation Receipt
              <input
                ref={ref}
                id="file-upload"
                type="file"
                required
                className={"flex-1 border border-gray-300 rounded p-3.5"}
                onChange={(e) => {
                  if (!e.target.files) {
                    setDonationReceiptFile(null);
                  } else {
                    setDonationReceiptFile(e.target.files[0]);
                  }
                }}
              />
            </label>
          </div>

          {isFormSubmitting ? (
            <div className={"self-center my-8 max-w-1/2 px-5 py-3.5"}>
              <TailSpin color={"#F59E0B"} height={50} width={50} />
            </div>
          ) : (
            <input
              type="submit"
              value={"Submit Request"}
              className={
                "self-center my-10 max-w-1/2 rounded-lg bg-amber-500 text-slate-800 px-5 sm:text-lg py-3.5 sm:px-10 cursor-pointer hover:bg-amber-500"
              }
            />
          )}
        </form>
      )}
      {userError && <ValidationFailure type={"user"} close={setUserError} />}

      {charityError && (
        <ValidationFailure type={"charity"} close={setCharityError} />
      )}
    </>
  );
};

export default RequestForm;
