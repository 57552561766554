import React from "react";
import Header from "./components/Header";
import RequestForm from "./components/RequestForm";
import { createTheme, ThemeProvider } from "@mui/material";

const theme = createTheme({
  typography: {
    fontFamily: ['"Montserrat"', "Open Sans"].join(","),
  },
});
const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Header />
      <main
        className={
          "flex flex-col gap-10 pt-3.5 sm:px-32 items-center md:items-start xl:px-60"
        }
      >
        <div className={"flex flex-col gap-3.5 px-3.5 sm:text-left"}>
          <h1 className={"font-semibold text-2xl"}>Request A Match</h1>
          <p className={"header-body text-[98%]"}>
            If you have already made a donation and can upload your receipt,
            request a match below. It will typically take only a few business
            days to review your match once you submit the form. If you have any
            questions about this form, please contact{" "}
            {process.env.REACT_APP_SUPPORT_EMAIL}.
          </p>
          <RequestForm />
        </div>
      </main>
    </ThemeProvider>
  );
};

export default App;
