import { FileUpload } from "../components/RequestForm";
import axios from "../utils/axios";

export const getFileUploadURLs = async (data: FileUpload) => {
  const {
    data: { response, error },
  } = await axios.post(`/.netlify/functions/file-upload-s3`, data, {
    headers: {
      accept: "application/json",
      "Content-Type": `application/json`,
    },
  });

  return { error, response };
};
